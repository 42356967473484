/* Common styles */
body {
    font-family: Arial, sans-serif;
    transition: background-color 0.5s, color 0.5s;
}

button.theme-toggle {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px;
    border: none;
    cursor: pointer;
    background-color: #333;
    color: white;
    border-radius: 5px;
}
section {
    padding: 20px;
}

/*section {*/
/*    padding: 20px;*/
/*    border-bottom: 1px solid #ddd;*/
/*}*/

/*h1, h2 {*/
/*    color: #333;*/
/*}*/

/*!* Light theme *!*/
/*.App.light {*/
/*    background-color: white;*/
/*    color: black;*/
/*}*/

/*!* Dark theme *!*/
/*.App.dark {*/
/*    background-color: #1e1e1e;*/
/*    color: white;*/
/*}*/

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

/*.loading h1 {*/
/*    margin-bottom: 20px;*/
/*}*/

.spinner {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #333;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

nav {
    display: flex;
    justify-content: center;
    background-color: #1f2937;
    padding: 10px;
}

nav a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    padding: 8px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

nav a:hover {
    background-color: #aa2525;
}

/* Light theme hover */
.App.light nav a:hover {
    background-color: #4A90E2; /* A different color for the light theme */
}

/* Scrollbar styles for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color, #4A90E2); /* Default thumb color */
    border-radius: 10px; /* Rounded edges */
}

::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color, #F5F5F5); /* Default track color */
    border-radius: 10px; /* Rounded edges */
}

/* Scrollbar styles for Firefox */
* {
    scrollbar-width: thin; /* Make the scrollbar thinner */
    scrollbar-color: var(--scrollbar-thumb-color, #4A90E2) var(--scrollbar-track-color, #F5F5F5); /* Thumb color and track color */
}

:root {
    --scrollbar-thumb-color: #4A90E2; /* Light theme thumb color */
    --scrollbar-track-color: #F5F5F5; /* Light theme track color */
}

.dark {
    --scrollbar-thumb-color: #2C3E50; /* Dark theme thumb color */
    --scrollbar-track-color: #34495E; /* Dark theme track color */
}
